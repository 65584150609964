exports.components = {
  "component---src-data-pages-404-index-jsx": () => import("./../../../src/data/pages/404/index.jsx" /* webpackChunkName: "component---src-data-pages-404-index-jsx" */),
  "component---src-data-pages-en-index-jsx": () => import("./../../../src/data/pages/en/index.jsx" /* webpackChunkName: "component---src-data-pages-en-index-jsx" */),
  "component---src-data-pages-en-know-the-sun-index-jsx": () => import("./../../../src/data/pages/en/know_the_sun/index.jsx" /* webpackChunkName: "component---src-data-pages-en-know-the-sun-index-jsx" */),
  "component---src-data-pages-en-legal-contact-us-index-jsx": () => import("./../../../src/data/pages/en/legal/contact-us/index.jsx" /* webpackChunkName: "component---src-data-pages-en-legal-contact-us-index-jsx" */),
  "component---src-data-pages-en-legal-cookie-policy-index-jsx": () => import("./../../../src/data/pages/en/legal/cookie-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-en-legal-cookie-policy-index-jsx" */),
  "component---src-data-pages-en-legal-legal-notice-index-jsx": () => import("./../../../src/data/pages/en/legal/legal-notice/index.jsx" /* webpackChunkName: "component---src-data-pages-en-legal-legal-notice-index-jsx" */),
  "component---src-data-pages-en-legal-private-policy-index-jsx": () => import("./../../../src/data/pages/en/legal/private-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-en-legal-private-policy-index-jsx" */),
  "component---src-data-pages-en-our-heritage-index-jsx": () => import("./../../../src/data/pages/en/our-heritage/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-heritage-index-jsx" */),
  "component---src-data-pages-en-our-products-aftersun-index-jsx": () => import("./../../../src/data/pages/en/our-products/aftersun/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-aftersun-index-jsx" */),
  "component---src-data-pages-en-our-products-all-products-index-jsx": () => import("./../../../src/data/pages/en/our-products/all_products/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-all-products-index-jsx" */),
  "component---src-data-pages-en-our-products-allergy-index-jsx": () => import("./../../../src/data/pages/en/our-products/allergy/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-allergy-index-jsx" */),
  "component---src-data-pages-en-our-products-index-jsx": () => import("./../../../src/data/pages/en/our-products/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-index-jsx" */),
  "component---src-data-pages-en-our-products-instantglow-index-jsx": () => import("./../../../src/data/pages/en/our-products/instantglow/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-instantglow-index-jsx" */),
  "component---src-data-pages-en-our-products-moisturising-index-jsx": () => import("./../../../src/data/pages/en/our-products/moisturising/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-moisturising-index-jsx" */),
  "component---src-data-pages-en-our-products-mountain-index-jsx": () => import("./../../../src/data/pages/en/our-products/mountain/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-mountain-index-jsx" */),
  "component---src-data-pages-en-our-products-protect-cool-index-jsx": () => import("./../../../src/data/pages/en/our-products/protect_cool/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-protect-cool-index-jsx" */),
  "component---src-data-pages-en-our-products-tan-protect-index-jsx": () => import("./../../../src/data/pages/en/our-products/tan_protect/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-tan-protect-index-jsx" */),
  "component---src-data-pages-en-our-products-ultra-light-index-jsx": () => import("./../../../src/data/pages/en/our-products/ultra_light/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-ultra-light-index-jsx" */),
  "component---src-data-pages-en-our-products-wetskin-index-jsx": () => import("./../../../src/data/pages/en/our-products/wetskin/index.jsx" /* webpackChunkName: "component---src-data-pages-en-our-products-wetskin-index-jsx" */),
  "component---src-data-pages-es-conozca-el-sol-index-jsx": () => import("./../../../src/data/pages/es/conozca-el-sol/index.jsx" /* webpackChunkName: "component---src-data-pages-es-conozca-el-sol-index-jsx" */),
  "component---src-data-pages-es-donde-has-estado-index-jsx": () => import("./../../../src/data/pages/es/donde-has-estado/index.jsx" /* webpackChunkName: "component---src-data-pages-es-donde-has-estado-index-jsx" */),
  "component---src-data-pages-es-index-jsx": () => import("./../../../src/data/pages/es/index.jsx" /* webpackChunkName: "component---src-data-pages-es-index-jsx" */),
  "component---src-data-pages-es-legal-aviso-legal-index-jsx": () => import("./../../../src/data/pages/es/legal/aviso-legal/index.jsx" /* webpackChunkName: "component---src-data-pages-es-legal-aviso-legal-index-jsx" */),
  "component---src-data-pages-es-legal-contactos-index-jsx": () => import("./../../../src/data/pages/es/legal/contactos/index.jsx" /* webpackChunkName: "component---src-data-pages-es-legal-contactos-index-jsx" */),
  "component---src-data-pages-es-legal-politicia-de-cookies-index-jsx": () => import("./../../../src/data/pages/es/legal/politicia-de-cookies/index.jsx" /* webpackChunkName: "component---src-data-pages-es-legal-politicia-de-cookies-index-jsx" */),
  "component---src-data-pages-es-legal-politicia-de-privacidad-index-jsx": () => import("./../../../src/data/pages/es/legal/politicia-de-privacidad/index.jsx" /* webpackChunkName: "component---src-data-pages-es-legal-politicia-de-privacidad-index-jsx" */),
  "component---src-data-pages-es-nuestra-historia-index-jsx": () => import("./../../../src/data/pages/es/nuestra-historia/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestra-historia-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-active-protect-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/active-protect/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-active-protect-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-after-sun-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/after-sun/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-after-sun-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-allergy-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/allergy/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-allergy-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-galeria-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/galeria/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-galeria-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-hydro-infusion-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/hydro-infusion/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-hydro-infusion-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-instantglow-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/instantglow/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-instantglow-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-moisturising-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/moisturising/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-moisturising-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-mountain-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/mountain/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-mountain-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-protect-cool-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/protect_cool/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-protect-cool-index-jsx" */),
  "component---src-data-pages-es-nuestros-productos-tan-protect-index-jsx": () => import("./../../../src/data/pages/es/nuestros-productos/tan-protect/index.jsx" /* webpackChunkName: "component---src-data-pages-es-nuestros-productos-tan-protect-index-jsx" */),
  "component---src-data-pages-gr-index-jsx": () => import("./../../../src/data/pages/gr/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-index-jsx" */),
  "component---src-data-pages-gr-know-the-sun-index-jsx": () => import("./../../../src/data/pages/gr/know_the_sun/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-know-the-sun-index-jsx" */),
  "component---src-data-pages-gr-legal-contact-us-index-jsx": () => import("./../../../src/data/pages/gr/legal/contact-us/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-legal-contact-us-index-jsx" */),
  "component---src-data-pages-gr-legal-cookie-policy-index-jsx": () => import("./../../../src/data/pages/gr/legal/cookie-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-legal-cookie-policy-index-jsx" */),
  "component---src-data-pages-gr-legal-legal-notice-index-jsx": () => import("./../../../src/data/pages/gr/legal/legal-notice/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-legal-legal-notice-index-jsx" */),
  "component---src-data-pages-gr-legal-private-policy-index-jsx": () => import("./../../../src/data/pages/gr/legal/private-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-legal-private-policy-index-jsx" */),
  "component---src-data-pages-gr-our-heritage-index-jsx": () => import("./../../../src/data/pages/gr/our-heritage/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-heritage-index-jsx" */),
  "component---src-data-pages-gr-our-products-aftersun-index-jsx": () => import("./../../../src/data/pages/gr/our-products/aftersun/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-aftersun-index-jsx" */),
  "component---src-data-pages-gr-our-products-gallery-index-jsx": () => import("./../../../src/data/pages/gr/our-products/gallery/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-gallery-index-jsx" */),
  "component---src-data-pages-gr-our-products-hydro-infusion-index-jsx": () => import("./../../../src/data/pages/gr/our-products/hydro-infusion/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-hydro-infusion-index-jsx" */),
  "component---src-data-pages-gr-our-products-index-jsx": () => import("./../../../src/data/pages/gr/our-products/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-index-jsx" */),
  "component---src-data-pages-gr-our-products-instantglow-index-jsx": () => import("./../../../src/data/pages/gr/our-products/instantglow/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-instantglow-index-jsx" */),
  "component---src-data-pages-gr-our-products-moisturising-index-jsx": () => import("./../../../src/data/pages/gr/our-products/moisturising/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-moisturising-index-jsx" */),
  "component---src-data-pages-gr-our-products-protect-cool-index-jsx": () => import("./../../../src/data/pages/gr/our-products/protect_cool/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-protect-cool-index-jsx" */),
  "component---src-data-pages-gr-our-products-sensitive-index-jsx": () => import("./../../../src/data/pages/gr/our-products/sensitive/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-sensitive-index-jsx" */),
  "component---src-data-pages-gr-our-products-tan-protect-index-jsx": () => import("./../../../src/data/pages/gr/our-products/tan_protect/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-tan-protect-index-jsx" */),
  "component---src-data-pages-gr-our-products-ultra-light-index-jsx": () => import("./../../../src/data/pages/gr/our-products/ultra_light/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-ultra-light-index-jsx" */),
  "component---src-data-pages-gr-our-products-wetskin-index-jsx": () => import("./../../../src/data/pages/gr/our-products/wetskin/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-our-products-wetskin-index-jsx" */),
  "component---src-data-pages-gr-where-have-you-been-index-jsx": () => import("./../../../src/data/pages/gr/Where-Have-You-Been/index.jsx" /* webpackChunkName: "component---src-data-pages-gr-where-have-you-been-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-it-index-jsx": () => import("./../../../src/data/pages/it/index.jsx" /* webpackChunkName: "component---src-data-pages-it-index-jsx" */),
  "component---src-data-pages-it-know-the-sun-index-jsx": () => import("./../../../src/data/pages/it/know_the_sun/index.jsx" /* webpackChunkName: "component---src-data-pages-it-know-the-sun-index-jsx" */),
  "component---src-data-pages-it-legal-contact-us-index-jsx": () => import("./../../../src/data/pages/it/legal/contact-us/index.jsx" /* webpackChunkName: "component---src-data-pages-it-legal-contact-us-index-jsx" */),
  "component---src-data-pages-it-legal-cookie-policy-index-jsx": () => import("./../../../src/data/pages/it/legal/cookie-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-it-legal-cookie-policy-index-jsx" */),
  "component---src-data-pages-it-legal-legal-notice-index-jsx": () => import("./../../../src/data/pages/it/legal/legal-notice/index.jsx" /* webpackChunkName: "component---src-data-pages-it-legal-legal-notice-index-jsx" */),
  "component---src-data-pages-it-legal-private-policy-index-jsx": () => import("./../../../src/data/pages/it/legal/private-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-it-legal-private-policy-index-jsx" */),
  "component---src-data-pages-it-ma-dove-sei-stata-index-jsx": () => import("./../../../src/data/pages/it/Ma-Dove-Sei-Stata/index.jsx" /* webpackChunkName: "component---src-data-pages-it-ma-dove-sei-stata-index-jsx" */),
  "component---src-data-pages-it-our-heritage-index-jsx": () => import("./../../../src/data/pages/it/our-heritage/index.jsx" /* webpackChunkName: "component---src-data-pages-it-our-heritage-index-jsx" */),
  "component---src-data-pages-it-prodotti-active-protect-index-jsx": () => import("./../../../src/data/pages/it/prodotti/active-protect/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-active-protect-index-jsx" */),
  "component---src-data-pages-it-prodotti-aftersun-index-jsx": () => import("./../../../src/data/pages/it/prodotti/aftersun/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-aftersun-index-jsx" */),
  "component---src-data-pages-it-prodotti-allergy-index-jsx": () => import("./../../../src/data/pages/it/prodotti/allergy/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-allergy-index-jsx" */),
  "component---src-data-pages-it-prodotti-gallery-index-jsx": () => import("./../../../src/data/pages/it/prodotti/gallery/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-gallery-index-jsx" */),
  "component---src-data-pages-it-prodotti-hydro-infusion-index-jsx": () => import("./../../../src/data/pages/it/prodotti/hydro-infusion/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-hydro-infusion-index-jsx" */),
  "component---src-data-pages-it-prodotti-index-jsx": () => import("./../../../src/data/pages/it/prodotti/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-index-jsx" */),
  "component---src-data-pages-it-prodotti-instantglow-index-jsx": () => import("./../../../src/data/pages/it/prodotti/instantglow/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-instantglow-index-jsx" */),
  "component---src-data-pages-it-prodotti-moisturising-index-jsx": () => import("./../../../src/data/pages/it/prodotti/moisturising/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-moisturising-index-jsx" */),
  "component---src-data-pages-it-prodotti-mountain-index-jsx": () => import("./../../../src/data/pages/it/prodotti/mountain/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-mountain-index-jsx" */),
  "component---src-data-pages-it-prodotti-protect-cool-index-jsx": () => import("./../../../src/data/pages/it/prodotti/protect_cool/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-protect-cool-index-jsx" */),
  "component---src-data-pages-it-prodotti-tan-protect-index-jsx": () => import("./../../../src/data/pages/it/prodotti/tan_protect/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-tan-protect-index-jsx" */),
  "component---src-data-pages-it-prodotti-ultra-light-index-jsx": () => import("./../../../src/data/pages/it/prodotti/ultra_light/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-ultra-light-index-jsx" */),
  "component---src-data-pages-it-prodotti-wetskin-index-jsx": () => import("./../../../src/data/pages/it/prodotti/wetskin/index.jsx" /* webpackChunkName: "component---src-data-pages-it-prodotti-wetskin-index-jsx" */),
  "component---src-data-pages-pt-a-nossa-historia-index-jsx": () => import("./../../../src/data/pages/pt/a_nossa_historia/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-a-nossa-historia-index-jsx" */),
  "component---src-data-pages-pt-index-jsx": () => import("./../../../src/data/pages/pt/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-index-jsx" */),
  "component---src-data-pages-pt-legal-aviso-legal-index-jsx": () => import("./../../../src/data/pages/pt/legal/aviso_legal/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-legal-aviso-legal-index-jsx" */),
  "component---src-data-pages-pt-legal-contactos-index-jsx": () => import("./../../../src/data/pages/pt/legal/contactos/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-legal-contactos-index-jsx" */),
  "component---src-data-pages-pt-legal-politica-de-privacidade-index-jsx": () => import("./../../../src/data/pages/pt/legal/politica_de_privacidade/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-legal-politica-de-privacidade-index-jsx" */),
  "component---src-data-pages-pt-legal-politicia-de-cookies-index-jsx": () => import("./../../../src/data/pages/pt/legal/politicia-de-cookies/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-legal-politicia-de-cookies-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-active-protect-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/active-protect/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-active-protect-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-aftersun-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/aftersun/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-aftersun-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-allergy-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/allergy/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-allergy-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-hydro-infusion-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/hydro-infusion/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-hydro-infusion-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-instantglow-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/instantglow/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-instantglow-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-moisturising-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/moisturising/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-moisturising-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-tan-protect-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/tan_protect/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-tan-protect-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-todos-os-produtos-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/todos_os_Produtos/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-todos-os-produtos-index-jsx" */),
  "component---src-data-pages-pt-nossos-produtos-ultra-light-index-jsx": () => import("./../../../src/data/pages/pt/nossos-produtos/ultra_light/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-nossos-produtos-ultra-light-index-jsx" */),
  "component---src-data-pages-pt-por-onde-andaste-index-jsx": () => import("./../../../src/data/pages/pt/Por-Onde-Andaste/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-por-onde-andaste-index-jsx" */),
  "component---src-data-pages-pt-sobre-o-sol-index-jsx": () => import("./../../../src/data/pages/pt/sobre_o_sol/index.jsx" /* webpackChunkName: "component---src-data-pages-pt-sobre-o-sol-index-jsx" */),
  "component---src-data-pages-uk-index-jsx": () => import("./../../../src/data/pages/uk/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-index-jsx" */),
  "component---src-data-pages-uk-know-the-sun-index-jsx": () => import("./../../../src/data/pages/uk/know_the_sun/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-know-the-sun-index-jsx" */),
  "component---src-data-pages-uk-legal-contact-us-index-jsx": () => import("./../../../src/data/pages/uk/legal/contact-us/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-legal-contact-us-index-jsx" */),
  "component---src-data-pages-uk-legal-cookie-policy-index-jsx": () => import("./../../../src/data/pages/uk/legal/cookie-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-legal-cookie-policy-index-jsx" */),
  "component---src-data-pages-uk-legal-legal-notice-index-jsx": () => import("./../../../src/data/pages/uk/legal/legal-notice/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-legal-legal-notice-index-jsx" */),
  "component---src-data-pages-uk-legal-private-policy-index-jsx": () => import("./../../../src/data/pages/uk/legal/private-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-legal-private-policy-index-jsx" */),
  "component---src-data-pages-uk-our-heritage-index-jsx": () => import("./../../../src/data/pages/uk/our-heritage/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-heritage-index-jsx" */),
  "component---src-data-pages-uk-our-products-aftersun-index-jsx": () => import("./../../../src/data/pages/uk/our-products/aftersun/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-aftersun-index-jsx" */),
  "component---src-data-pages-uk-our-products-allergy-index-jsx": () => import("./../../../src/data/pages/uk/our-products/allergy/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-allergy-index-jsx" */),
  "component---src-data-pages-uk-our-products-index-jsx": () => import("./../../../src/data/pages/uk/our-products/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-index-jsx" */),
  "component---src-data-pages-uk-our-products-instantglow-index-jsx": () => import("./../../../src/data/pages/uk/our-products/instantglow/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-instantglow-index-jsx" */),
  "component---src-data-pages-uk-our-products-moisturising-index-jsx": () => import("./../../../src/data/pages/uk/our-products/moisturising/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-moisturising-index-jsx" */),
  "component---src-data-pages-uk-our-products-mountain-index-jsx": () => import("./../../../src/data/pages/uk/our-products/mountain/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-mountain-index-jsx" */),
  "component---src-data-pages-uk-our-products-protect-cool-index-jsx": () => import("./../../../src/data/pages/uk/our-products/protect_cool/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-protect-cool-index-jsx" */),
  "component---src-data-pages-uk-our-products-tan-protect-index-jsx": () => import("./../../../src/data/pages/uk/our-products/tan_protect/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-tan-protect-index-jsx" */),
  "component---src-data-pages-uk-our-products-wetskin-index-jsx": () => import("./../../../src/data/pages/uk/our-products/wetskin/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-our-products-wetskin-index-jsx" */),
  "component---src-data-pages-uk-where-to-buy-index-jsx": () => import("./../../../src/data/pages/uk/where-to-buy/index.jsx" /* webpackChunkName: "component---src-data-pages-uk-where-to-buy-index-jsx" */)
}

